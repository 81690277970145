import { useLocation } from "react-router-dom";
import { lazy, useEffect, Suspense } from "react";
import config from "config";
const StrategyWorkshop = lazy(() => import("./AppSW"));
const Clearinghouse = lazy(() => import("./AppCH"));

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return config.clearingHouse ? (
    <Suspense fallback={<div>Loading...</div>}>
      <Clearinghouse />
    </Suspense>
  ) : (
    <Suspense fallback={<div>Loading...</div>}>
      <StrategyWorkshop />
    </Suspense>
  );
}

export default App;
