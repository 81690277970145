import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./common/library/datepicker/styles.css";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import config from "config";

const instance = config.matomoSiteId
  ? createInstance({
      urlBase: "https://edanalytics.matomo.cloud/",
      siteId: config.matomoSiteId,
    })
  : undefined;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
document.title = config.clearingHouse ? "Strategy Clearinghouse" : "Strategy Workshop";
root.render(
  instance ? (
    <React.StrictMode>
      <MatomoProvider value={instance}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MatomoProvider>
    </React.StrictMode>
  ) : (
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
