interface SWConfig {
  swApi: string | undefined; // This should never be undefined
  clearingHouse: boolean;
  matomoSiteId?: number;
}

const isTrue = (value?: string) => {
  if (value && value.localeCompare("true") === 0) {
    return true;
  }
  return false;
};

const config: SWConfig = {
  swApi: process.env.REACT_APP_WORKSHOP_API,
  clearingHouse: isTrue(process.env.REACT_APP_CLEARINGHOUSE ?? "false"),
  matomoSiteId: process.env.REACT_APP_MATOMO_SITE_ID
    ? Number(process.env.REACT_APP_MATOMO_SITE_ID)
    : undefined,
};

export default config;
